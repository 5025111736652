import type { Category } from '~/types/Category'

export default async function useCategories () {
  const categories = ref<Category[]>([])

  const data = await $fetch('/api/categories')

  categories.value = superjsonParse<Category[]>(data)

  function getCategoryBySlug (slug: string) {
    return categories.value?.find(c => c.slug === slug)
  }

  return {
    categories,
    getCategoryBySlug,
  }
}
